.colorful-rect {
    display: inline-block;
    text-align: center;
    border-radius: 0.3333em;
    padding: 0.6em 1.3333em;
    font-size: 0.9375rem;

    &--lg {
        border-radius: 0.27777em;
        padding: 0.5666em 0.8333em;
        font-size: 1.125rem;
        font-weight: 500;
        width: 100%;
        max-width: 5.4444em;
    }
}