
.text-result {
    font-size: 1.5rem;
    font-weight: 400;
}

@media (min-width: 768px) {
    .text-result {
    font-size: 1.875rem;
    }
}

.text-result__icon {
    position: relative;
    bottom: -2px;
}

.text-block {
    color: #808A9E;
}

.text-block__title {
    color: #131336;
    font-weight: 400;
}

.result-table {
    padding-left: 0;
    margin-top: 20px;

    li {
        margin-left: 20px;
    }
}