.course-schedule {
  &__wrapper {
    padding: 30px 30px 100px 30px;
    width: 358px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: #FCFCFC;
    border-radius: 12px;
    margin-bottom: auto;
  }

  &__header {
    &-text {
      font-size: 20px;
      font-weight: 500;
      color: rgba(46, 43, 56, 1);
      margin-bottom: 10px;
      line-height: 26px;
    }
  }

  &__header, &__toggle {
    display: flex;
    align-items: baseline;
  }

  &__toggle {
    a:first-of-type {
      margin-right: 22px;
    }

    p {
      margin-left: 16px;
    }
  }

  &__dates {
    p:not(:first-of-type) {
      margin-top: 20px;
    }

    p:first-of-type {
      margin-top: 16px;
    }
  }

  &__default-text {
    color: #4b4b60;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }
}
