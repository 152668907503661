.header {
    &.show {
        & .cabinet-header__footer {
            &-link {
                & i {
                    display: none;
                }
            }
        }
    }
    &.closed {
        max-width: 110px!important;
        & .logo {
            & .logo-group__name {
                display: none;
            }
            & .logo-group {
                min-width: unset;
            }

        }
        & .menu-list__link span {
            display: none;
        }
        & .cabinet-header__user-opener {
            display: none;
        }
    }
    & .tab-pane {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-button-container {
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
        width: 100%;
    }

    &-button-director-msg {
        display: flex;
        width: 200px;
        min-height: 32px;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        color: #007EEC;
        border: 1px solid #007EEC;
        border-radius: 4px;
        background: #ffffff;
        outline: none !important;
        transition: 0.3s;

        &:hover {
            background: #007EEC;
            color: #fff;
            cursor: pointer;
        }
    }

    &-modal {
        position: absolute;
    }
}

@media screen and (min-width: 1200px) {
    .header .tab-pane {
        display: flex !important;
    }
}

.cabinet-header__tab-menu {
    position: relative;
    height: -webkit-calc(100vh - 118px);
    height: calc(100vh - 118px);
}

@media screen and (min-width: 768px) {
    .header {
        &.hidden {
            & .cabinet-header__footer {
                width: 110px;
                &-link {
                    & span {
                        display: none;
                    }
                    & i {
                        display: block;;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .show .cabinet-header__tab-menu {
        opacity: 1;
    }
    .fade:not(.show) {
        opacity: 1;
    }

    .fade.tab-pane:not(.active) {
        display: none!important;
    }

    .header {
        &.hidden {
            .pupil-nav__container.nav.nav-justified {
                display: none!important;
            }
        }
    }
}

@media screen and (max-width : 768px) {
    .header {
        &.closed {
            max-width: 0 !important;
        }
        &.hidden {
            & .logo {
                & .logo-group__name {
                    display: block;
                }
            }
        }
    }
    .fade.tab-pane:not(.active) {
        display: none!important;
    }

    .show .cabinet-header__tab-menu  {
        position: absolute;
        top: 80px;
        bottom: 0;
        left: 0;
        width: 100%;
        opacity: 1;
        height: calc(100vh - 86px);
        box-sizing: border-box;
        overflow: scroll;
        display: flex!important;
    }

    .tab-pane {
        z-index: 1000;
        background-color: #fff;
        & .pupil-nav__container.nav {
            height: unset;
        }
    }

    .header-tab {
        & .tab-pane {
            padding: 20px;
        }
        & .cabinet-header__user-container .cabinet-header__user.card-header {
            padding-top: 20px;
        }
    }


}

.page-header {
    z-index: 200;

    &_isMobile {
        width: 100%;
        min-height: unset;
        position: fixed;
    }

    .navbarItem {
        &-icon {
            height: 29px;
        }

        &-text {
            height: 26px;
        }
    }

    a:hover {
        text-decoration: none;
    }
}
