.empty-marks {
    .dashboard__item {
        margin: 0;
    }

    &__marks {
        max-width: 550px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
}
