.jcf-select {
    display: inline-block;
    vertical-align: top;
    position: relative;
    border: 1px solid #ced4da;
    border-radius: 5px;
    background: #fff;
    width: 100%;
    max-width: 400px;
    padding: 0.945rem 0.945rem;
    cursor: pointer;
    min-height: 56px;
    height: unset;
}

.select__wrapper {
    position: relative;
}

.jcf-select select {
    z-index: 1;
    left: 0;
    top: 0;
}

.jcf-select .jcf-select-text {
    text-align: left;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    display: block;
    font-size: 1rem;
    margin: 0 35px 0 0;
}

.jcf-select .jcf-select-opener {
    position: absolute;
    text-align: center;
    background: #aaa;
    width: 22px;
    height: 22px;
    right: 0.945rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    border-radius: 50%;
    background: rgba(162, 162, 195, 0.1);
}

.jcf-select .jcf-select-opener::before {
    font-family: 'icomoon';
    content: '\e90c';
    color: #ced4da;
    position: absolute;
    font-size: 10px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.jcf-select-drop {
    position: absolute;
    margin: -1px 0 0;
    z-index: 30;
    border-radius: 0 0 5px 5px;
    padding: 80px 10px 10px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.07);
            box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.07);
    top: -10px;
    width: 100%;
    z-index: 1;
}

body > .jcf-select-drop.jcf-drop-flipped {
    margin: 1px 0 0;
}

.jcf-select .jcf-select-drop {
    position: absolute;
    margin-top: 0;
    z-index: 30;
    top: 100%;
    left: -1px;
    right: -1px;
}

.jcf-select .jcf-drop-flipped {
    bottom: 100%;
    top: auto;
}

.jcf-select-drop .jcf-select-drop-content {
    overflow: hidden;
    border-top: none;
    border-radius: 0 0 5px 5px;
}

/* multiple select styles */
.jcf-list-box {
    overflow: hidden;
    display: inline-block;
    border: 1px solid #b8c3c9;
    min-width: 200px;
    margin: 0 15px;
}

/* select options styles */
.jcf-list {
    display: inline-block;
    vertical-align: top;
    position: relative;
    background: #fff;
    font-size: 1rem;
    width: 100%;
}

.jcf-list .jcf-list-content {
    vertical-align: top;
    display: inline-block;
    overflow: auto;
    width: 100%;
    max-height: 250px !important;
}

.jcf-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.jcf-list ul li {
    overflow: hidden;
    display: block;
}

.jcf-list .jcf-overflow {
    overflow: auto;
}

.jcf-list .jcf-option {
    overflow: hidden;
    cursor: default;
    display: block;
    padding: 0.945rem 0.945rem;
    color: #656565;
    height: 1%;
}

.jcf-list .jcf-disabled {
    background: #fff !important;
    color: #aaa !important;
}

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
    background: #f8f9fa;
}

.jcf-list .jcf-optgroup-caption {
    white-space: nowrap;
    font-weight: bold;
    display: block;
    padding: 5px 9px;
    cursor: default;
    color: #000;
}

.jcf-list .jcf-optgroup .jcf-option {
    padding-left: 30px;
}

/* common custom form elements styles */
.jcf-disabled {
    background: #ddd !important;
}

.jcf-focus,
.jcf-focus * {
    border-color: #007EEC;
}

.jcf-focus + label,
.jcf-focus * + label {
    opacity: 1;
    visibility: visible;
    -webkit-transition-delay: 0s;
        -o-transition-delay: 0s;
            transition-delay: 0s;
}

.jcf-focus::-webkit-input-placeholder,
.jcf-focus *::-webkit-input-placeholder {
    color: transparent;
}

.jcf-focus::-moz-placeholder,
.jcf-focus *::-moz-placeholder {
    opacity: 1;
    color: transparent;
}

.jcf-focus:-moz-placeholder,
.jcf-focus *:-moz-placeholder {
    color: transparent;
}

.jcf-focus:-ms-input-placeholder,
.jcf-focus *:-ms-input-placeholder {
    color: transparent;
}

.jcf-focus.placeholder,
.jcf-focus *.placeholder {
    color: transparent;
}

.jcf-select-opener-active::before {
    -webkit-transform: translate(-50%, -50%) rotate(180deg)!important;
        -ms-transform: translate(-50%, -50%) rotate(180deg)!important;
            transform: translate(-50%, -50%) rotate(180deg)!important;
}

.jcf-select-wrapper {
    position: relative;
    margin: 0 auto !important;
    width: 100%;
    max-width: 345px;
    margin-bottom: 30px !important;
    z-index: 20;
}


.jcf-select-wrapper .form-group {
    margin: 0 !important;
}

.jcf-select-wrapper small {
    position: relative;
    display: inline-block;
    margin: 10px 0;
    z-index: -1;
}

.jcf-select-wrapper-active {
    z-index: 40;
}

.jcf-select-wrapper-active small {
    display: none;
}

.form-group--not-empty .jcf-select {
    border-color: #007EEC;
}

.jcf-hidden {
    display: none;
}