.schedulePage {
    display: flex;
    flex-direction: column;

    &.layout {
        padding: 36px 60px 20px 60px;
        width: 100%;
        max-height: 100vh;
        min-height: 100vh;
        overflow-y: scroll;
        
        @media screen and (max-width: 768px) {
            padding: 30px 0 20px 0;
        }
    }

    &-wrapper {
        padding: 0;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        background-color: #f9fafd;

        @media screen and (max-width: 768px) {
            padding: 0 15px;
        }
    }

    &-pageHeader {
        margin-bottom: 20px;
    }

    &-headerContainer {
        display: grid;

        @media screen and (max-width: 1340px) {
            grid-template-columns: 1fr auto;
        }
    }

    &-header {
        @media screen and (max-width: 1340px) {
            grid-column: 2;
            grid-row: 1;
        }

        @media screen and (max-width: 1060px) {
            grid-column: 2;
            grid-row: 1;
        }
    }

    &-scheduleHeader {
        @media screen and (max-width: 1340px) {
            grid-column: 1;
            grid-row: 1 / 3;
        }

        @media screen and (max-width: 1060px) {
            grid-column: 1;
            grid-row: 1 / 3;
        }
    }

    &-scheduleContainer {
        height: 100vh;
        position: relative;
    }

    &-schedule {
        position: relative;
        height: 100%;
        padding: 0;
    }

    .calendarDay {
        height: 100%;
    }

    .calendarWeek {
        background-color: var(--color-essential);
        height: 100%;
    }

    .calendarMonth {


        .scheduleWithDisabledItems-title {
            grid-column: 1 / 7;
            padding: 20px;
            padding-bottom: 100px;
        }

        .dayOnCalendar-dateTitle {

            @media screen and (max-width: 900px) {
                white-space: unset;
            }
        }
    }

    .calendarWeek-schedule {
        min-height: 100%;
    }

    .dateSlider {
        align-items: baseline;
    }

    .calendarDay-timeContainer {
        left: -42px;
        width: 40px;
    }

    .calendarWeek-timeContainer {
        left: -48px;
        width: 40px;
    }

    .lesson-date-card {
        cursor: unset;
    }

    .homework-item {
        cursor: unset;
    }
}
