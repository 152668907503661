
.meeting {
    position: relative;
}

.meeting__panel {
    margin-bottom: 30px;
}

.meeting__input {
    margin: 0 0 30px 0!important;
    max-width: 400px!important;
}

.meeting__input-error {
    border-color: red!important;
}

.text-bold {
    font-weight: 500;
}

@media (min-width: 1024px) {
    .meeting__panel {
        margin-bottom: 60px;
    }
}

.text-danger_no_teacher{
    margin-bottom: 30px;
}

.meeting__form {
    width: 100%;
    column-gap: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.meeting__form > div {
    width: 100%;
}

.meeting__form-skeleton{
    margin-bottom: 30px;
}

@media (min-width: 1024px) {
    .meeting__form > div {
        width: auto;
    }
}


.meeting__form > div:first-child {
    width: auto;
}

.meeting__form-select {
    width: 100%;
    max-width: 370px;
    margin: 0 !important;
}

.meeting__form-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 20px;
}

.meeting__form-success-text {
    display: inline-block;
    font-size: 0.875rem;
    white-space: nowrap;
    -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
            transform: translateY(-5px);
}

.meeting__form-btn {
    margin: 0 25px 15px 0;
}

.meeting__date-list {
    margin-bottom: 30px;
    font-size: 0.6875rem;
    width: 26.25em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-left: -0.4166em;
    margin-right: -0.4166em;
}

@media (min-width: 360px) {
    .meeting__date-list {
        font-size: 0.75rem;
    }
}

.meeting__date-list li {
    width: 7.9166em;
    min-height: 2.5em;
    margin: 0 0.4166em 0.8333em;
}

.meeting-radio {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.meeting-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.meeting-radio__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #FCFCFC;
    padding: 0.5em 1em;
    color: #4B4B60;
    border-radius: 0.4166em;
    white-space: nowrap;
    text-align: center;
    width: 7.9166em;
}

.meeting-radio__selected {
    background: #00AF01;
    color: #fff;
}

.meeting-radio__checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.meeting-radio:hover input ~ .meeting-radio__checkmark {
    background-color: #dee2e6;
}

input:checked ~ .meeting-radio__checkmark {
    background-color: #dee2e6;
    font-weight: 500;
}


.meeting .cabinet-content__text {
    font-size: 1rem;
    font-weight: 300;
    max-width: 975px;
}

@media (min-width: 1600px) {
    .meeting .cabinet-content__text {
        font-size: 1.25rem;
    }
}

.meeting .cabinet-content__text--bold {
    font-weight: 500;
}







