.courses-options {
  margin-left: 34px;
  margin-top: 70px;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 124px;
    padding-left: 5px;
  }
}
