.cabinet-content{
    &__header {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-box-align: baseline;
        -webkit-align-items: baseline;
            -ms-flex-align: baseline;
                align-items: baseline;
        margin-bottom: 15px;
    }

    &__title {
        font-size: 20px;
        display: inline-block;
        margin: 0 15px 0 0;
    }
    &__loading {
        & .cabinet-content__title {
            height: 20px;
            width: 100px;
            max-width: 100%;
        }
    }
}


@media (min-width: 414px) {
    .cabinet-content{
        &__title {
            font-size: 25px;
        }
    }
}

