.nav-bar-content {

    &_isDesktop {
      overflow-y: scroll;
      height: calc(100vh - 204px);
    }

    &_isOpened {
      height: calc(100vh - 156px);
    }

    &_isMobile {
      height: 100%;
      position: relative;
    }

    &-students {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 8px 0;

      &_isMobile {
        margin-top: 0;
      }
    }

    &-nav-items {
      width: 100%;
      margin: 10px 0;
      padding-left: 10px;
      &_isMobile {
        box-sizing: border-box;
        padding: 40px 0;
        margin: 0;
      }

      &_isHidden {
        margin-left: 0;
      }
    }


  &-skeleton-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 12px 22px;
  }

    &-divider {
      width: 100%;
      height: 1px;
      background-color: #EDEEF2;
      transition: all 1s ease;
    }

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
  }
