.courses-attended {
  padding-bottom: 90px;
  margin-top: 36px;

  &__skeleton-container {
    display: flex;
    flex-direction: column;
  }

  &__row {

    &:not(:first-of-type) {
      margin-top: 60px;
    }

    &:first-of-type {
      margin-top: 0;
    }

    &-header-text {
      margin-bottom: 16px;
      font-size: 25px;
      font-weight: 500;
      color: rgba(19, 19, 54, 1);

      & > button {
        margin-left: 10px;
      }
    }

    &-cards {
      max-width: 1500px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(344px, 1fr));
      grid-column-gap: 40px;
      grid-row-gap: 30px;
    }
  }
}
