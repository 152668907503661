.custom-tooltip {
    width: max-content;
    position: relative;
    padding: 10px 24px 15px;
    background: white;
    border: 1px solid #EEF4FB;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 8px 32px rgba(20, 49, 127, 0.08);
    font-size: 13px;
    line-height: 1.2;
    opacity: 100%;

    &_view {
        &_comment {
            padding: 7px 14px 10px;
        }
    }

    &-pointer {
        position: absolute;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        background-color: white;
        border-bottom: 1px solid #EEF4FB;
        border-right: 1px solid #EEF4FB;
        box-shadow: 0px 8px 32px rgba(20, 49, 127, 0.08);
        bottom: -6px;
        left: calc(50% - 3.5px);
    }

    &-meta-data {
        font-size: 13px;
        font-weight: 700;
    }

    &-footnote {
        font-size: 13px;
        font-style: italic;
        font-weight: 400;
    }

    &-comment {
        font-weight: 400;
    }
}
