.pageHeader {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      .avatar-with-tooltip {
        display: none;
      }
    }
  }
}
