.nav-bar-student {
  display: flex;
  padding: 0 10px 0 8px;
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
    cursor: pointer;
    &_isInHeader {
      cursor: default;
    }
    &_isSelect {
      background: #0088e9;
      border-radius: 6px;
      height: 100%;
    }
  }
  &-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  &-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
    .AppText {
      width: 136px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &_isInHeader {
      .AppText {
        text-align: end;
        margin-right: 8px;
      }
    }
    &_isMobile {
      .AppText {
        width: 100%;
      }
    }
  }
  &-image {
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    border: 1px solid transparent;
    box-sizing: border-box;
    &-logo {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      object-fit: cover;
    }
  }
  &-settings {
    padding-top: 4px;
    display: flex;
    align-items: flex-start;
    &-icon {
      width: 14px;
      height: 14px;
      &_isSelected {
        & path {
          fill:#fff;
        }
      }
    }
  }
}
