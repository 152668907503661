.button {
    border: none;
    cursor: pointer;
    outline: none;
    transition: transform 0.1s ease-out, color 0.15s linear;
    border-radius: var(--button-border-radius);

    &-text {
        width: 100%;
        font-family: 'Source Sans Pro';
        font-size: 15px !important;
        line-height: 20px;

        &_with-icon {
            display: flex;
            align-items: center;

            & svg {
                text-align: center;
            }
        }

        @keyframes rot {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        &_loading {
            animation: 1.5s linear 0s normal none infinite running rot;
        }
    }

    &_size {
        &_l {
            width: 100%;
            padding: var(--button-size-l-text-indent-all);
            font-size: var(--button-size-l-font-size);
            height: var(--button-size-l-height);
            display: flex;
            align-items: center;
        }

        &_m {
            padding: var(--button-size-m-text-indent-all);
            font-size: var(--button-size-m-font-size);
            height: var(--button-size-m-height);
        }

        &_s {
            font-size: var(--button-size-s-font-size);
            height: var(--button-size-s-height);
            width: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &_view {
        &_action {
            background-color: var(--button-view-action-fill);
            color: var(--button-color-text);

            & .button-text {
                color: var(--button-view-action-typo-color-base);
            }

            &:hover {
                background-color: var(--color-hover-blue);
            }
        }

        &_bordered {
            background-color: transparent;
            border: 1px solid var(--button-view-bordered-typo-color-base);

            .button-text {
                color: var(--button-view-bordered-typo-color-base);

                path {
                    fill: var(--button-view-bordered-typo-color-base);
                }
            }

            &:hover {
                border-color: var(--color-hover-blue);

                .button-text {
                    color: var(--color-hover-blue);

                    path {
                        fill: var(--color-hover-blue);
                    }
                }
            }
        }

        &_danger {
            border-color: var(--color-red-main);
            background: var(--color-red-main);
            color: var(--button-color-text);

            &:hover {
                background: #f07575;
                border-color: #f07575;
            }
        }
    }

    &_disabledBlueButton {
        &_disabledBlue {
            background-color: transparent;
            border: 1px solid var(--button-view-bordered-typo-color-base);
            cursor: default;
            pointer-events: none;

            .button-text {
                color: var(--button-view-bordered-typo-color-base);

                path {
                    fill: var(--button-view-bordered-typo-color-base);
                }
            }
        }
        &_disabled.button_view_action {
            background-color: var(--button-view-action-fill-disabled);
            cursor: default;

            &:hover {
                background-color: var(--button-view-action-fill-disabled);
            }
        }
        &_disabled.button_view_bordered {
            border: 1px solid var(--button-view-bordered-typo-color-base-disabled);
            cursor: default;

            & .button-text {
                color: var(--button-view-bordered-typo-color-base-disabled);

                & path {
                    fill: var(--button-view-bordered-typo-color-base-disabled);
                }
            }

            &:hover {
                border: 1px solid var(--button-view-bordered-typo-color-base-disabled);

                .button-text {
                    color: var(--button-view-bordered-typo-color-base-disabled);
                }
            }
        }
    }

    &:hover {
        box-shadow: 0, 8px rgba(20, 49, 127, 0.08);
    }
}
