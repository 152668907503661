.notification {
    position: relative;
    border-radius: 5px;
    border: none;

    display: flex;
    flex-direction: row;
    
    .title {
        font-size: 23px;
        font-weight: 600;
        font-style: normal;
        line-height: 150%;
        text-align: left;
        margin: 0;
        margin-top: 4px;

        max-width: 516px;

        &__large {
            max-width: none;
        }

        @media screen and (max-width: 1024px)  {
            font-size: 20px;
        }

        @media screen and (max-width: 600px)  {
            font-size: 18px;
        }
    }

    .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 27px;
        text-align: left;
        margin: 0px;
        margin-top: 8px;
        
        max-width: 640px; 
        
        a {
            text-decoration: underline;
            color: inherit;

            &:visited {
                text-decoration: none;
            }
        }

        &__large {
            max-width: none;
        }

        @media screen and (max-width: 1024px)  {
            font-size: 14px;
        }

        @media screen and (max-width: 600px)  {
            font-size: 13px;
        }

        &__no-mg {
            margin: 0;
        }
    }

    button {
        border: none;
        border-radius: 3px;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        text-align: center;
        margin-right: 10px;

        min-width: 140px;
        min-height: 36px;
        padding: 5px 32px;
        
        &:hover {
            cursor: pointer;
            box-shadow: 0px 0px 4px 2px #c0c2c963;
        }

        &:focus {
            outline: none;
        }

        @media screen and (max-width: 1024px)  {
            min-width: 130px;
            font-size: 16px;
        }

        @media screen and (max-width: 600px)  {
            padding: 5px 10px;
            min-width: 40px;
            font-size: 14px;
        }
    }

    &__main-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }

    .button-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        margin-top: 12px;

        &__low-mg {
            margin-top: 10px !important;
        }
    }

    &__feedback {
        background-color: #00AF01;
        color: #FFFFFF;

        svg {
            path {
                stroke: #FFFFFF;
            }
        }

        &-light{
            color: #00AF01;
            background-color: #F1FBF1;

            svg {
                path {
                    stroke: #00AF01;
                }
            }
        }
    }

    &__alert {
        background-color: #FA1919;
        color: #FFEBEA;

        svg {
            path {
                stroke: #FFEBEA;
            }
        }

        &-light {
            background-color: #FFEBEA;
            color: #545454;
            
            .title {
                color: #FA1919 !important;
            }

            svg {
                path {
                    stroke: #FA1919;
                }
            }

        }
    }

    &__loading {
        background-color: #F3F1FF;
    }

    &__action {
        background-color: #007EEC;
        color: #FFFFFF;

        input:not(.checkDataForm__input) {
            border: 1px solid #FFFFFF;
            color: #FFFFFF;
            max-width: 100%;

            &::placeholder {
                color: #fcfcfc91;
            }
        }

        svg {
            path {
                stroke: #FFFFFF;
            }
        }

        .button-action {
            background-color:#F0F6FB;
            color: #007EEC;

            &__disabled {
                background-color: #46A2F3;

                &:hover {
                    cursor: default;
                    box-shadow: none;
                }
            }
        }

        .button-cancel {
            background: none;
            border: 1px solid #D9DCE2;
            color: #D9DCE2;
        }

        &-light{
            background-color: #fff;
            color: #007EEC;
            border: 1px solid #007EEC;

            input:not(.checkDataForm__input) {
                border: 1px solid #007EEC;
                color: #007EEC;
            }

            svg {
                path {
                    stroke: #007EEC;
                }
            }
    
            .button-action {
                background-color:#CBE1F4;
                color: #007EEC;
            }

            .button-cancel {
                background: none;
                border: 1px solid #BBC1C6;
                color: #BBC1C6;
            }
        }
    }

    &__placeholder {
        background: linear-gradient(271.97deg, #7C78EE 0.81%, #4039A1 99.84%);
        color: #FFFFFF;
        align-items: center;
        justify-content: space-between;

        padding: 0 0px 0px 30px!important;
    }

    &__approve {
        background-color: #007EEC;
        color: #ffffff;
    }

    &__block {
        background-color: #f9cccc;
        color: #ea5959;
    }

    &__upload {
        background-color: #de7f16;
        color: #ffffff;
    }

    &__edit {
        background-color: #de4444;
        color: #ffffff;
    }

    &__work {
        background-color: #6a44de;
        color: #ffffff;
    }

    &__s {
        width: 400px;
        min-height: 145px;
        padding: 24px 40px 24px 32px;
    }

    &__m {
        width: 600px;
        min-height: 180px;
        padding: 32px 40px;
    }

    &__l {
        width: 100%;
        min-height: 212px;
        padding: 32px 50px;

        .button-container {
            margin-top: 28px;
        }

        @media screen and (max-width: 600px)  {
            padding: 24px 40px;
        }

        @media screen and (max-width: 600px)  {
            padding: 20px 20px;
        }
    }
    

    &__icon {
        position: absolute;
        top: 18px;
        right: 18px;
        cursor: pointer;

        svg {            
            width: 30px;
            height: 30px;
        }
    }

    img, canvas {
        margin-right: 28px;

        
        @media screen and (max-width: 600px)  {
            margin-right: 10px;
        }
    }

    canvas {
        border: 16px solid white;
        border-radius: 5px;
        @media screen and (max-width: 600px)  {
            border: 10px solid white;
            width: 70px !important;
            height: 70px !important;
        }
    }

    input:not(.checkDataForm__input) {
        background: none;
        border-radius: 5px;
        height: 40px;
        padding: 8px 16px;
        margin-top: 8px;

        &:focus-visible {
            outline: none;
            border-color: #FFFFFF;
        }
    }

    .placeholder_img {
        margin: 0;
    }
        
    @media screen and (max-width: 600px)  {
        align-items: center;
    }
}

.flex-center {
    align-items: center;
}
