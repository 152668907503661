.icon {
    display: inline-block;
    text-align: center;
    text-decoration: none !important;
    width: 38px;
    height: 38px;
    border: 1px solid rgba(162, 162, 195, 0.2);
    border-radius: 3px;
    -webkit-transition: border-color 0.3s ease-in-out;
    -o-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;

    i {
        color: inherit;
        line-height: 38px;
    }
}

.icon--red {
    color: #FA1919;

    &:hover, &:active, &:focus {
        color: #FA1919;
    }

    &:hover {
        border-color: #FA1919;
    }
}