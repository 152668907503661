.tabs {
    width: 100%;
    &-header {
        display: flex;
        border-bottom: 1px solid #EDEEF2;
        & .tabsHeaderItem:not(:last-child) {
            margin-right: 30px;
        }
    }
    &-content {
        position: relative;
    }
}
