.right-sidebar {
    width: 100%;
    overflow: hidden;
    padding: 0 15px;
  }
  
  @media (min-width: 1200px) {
    .right-sidebar {
      max-width: 400px !important;
    }
  }
  
  @media (min-width: 1600px) {
    .right-sidebar {
      max-width: 500px !important;
    }
  }
  
  .right-sidebar__list {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  @media (min-width: 768px) {
    .right-sidebar__list {
      margin-left: 0;
      margin-right: 0;
    }
  }

.document-warpper {
  padding: 36px 60px 20px 50px !important;

  @media screen and (max-width: 768px) {
    padding: 30px 0 0 0 !important;
  }
}

.document {
  &__pageHeader {
    &-container {
      padding-left: 10px;
      margin-bottom: 40px;
    }
  }
}

.documents {
  &-wrapper {
    padding: 0;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    @media screen and (max-width: 768px) {
      padding: 0 15px;
    }
  }
}

.big-media {
  text-align: center;
  margin: 60px auto 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (min-width: 1024px) {
  .big-media {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }
}

.big-media__img {
  width: 150px;
  margin: 0 auto 20px;
}

@media (min-width: 768px) {
  .big-media__img {
    width: 300px;
  }
}

@media (min-width: 1024px) {
  .big-media__img {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .big-media__img {
    width: 350px;
  }
}

@media (min-width: 1600px) {
  .big-media__img {
    width: 446px;
  }
}

.big-media__img img {
  width: 100%;
}

.big-media__content {
  max-width: 400px;
}

@media (min-width: 1024px) {
  .big-media__content {
    padding-left: 50px;
  }
}

.uploaded-docs {
  margin-top: 52px;
  padding-left: 0 !important;

  .btn, btn-primary {
    outline: none;
    cursor: pointer;
    font-size: 22px;
    margin: 24px 0 68px .75rem;
    padding: 16px 50px;

    &:hover {
      background-color: #1385ff;
      border-color: #1385ff;
    }
  }

}

.documentType-skeleton {
  margin-bottom: 56px;
  &-header {
    background-color: #F3F1FF;
    width: 100%;
    height: 24px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  &-content{
    background-color: #F3F1FF;
    width: 100%;
    height: 186px;
    border-radius: 4px;
    margin-bottom: 16px;
  }
}

.document-skeleton {
  margin-bottom: 40px;
  &-content {
    background-color: #F3F1FF;
    width: 100%;
    height: 60px;
    border-radius: 4px;
    margin-bottom: 12px;
  }
}

.enrollment-certificate {
  &__wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 64px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;

      & > button {
        margin-bottom: 20px;
      }

      & > p {
        margin: 20px 0 0 0;
      }
    }

    @media screen and (max-width: 400px) {
      & > button {
        width: 260px;
      }
      & > p {
        width: 260px;
      }
    }

    .select__wrapper {
      width: 270px;
    }

    .form-group {
      margin: 0 !important;
      width: 270px;
    }
  }

  &__button {
    width: 300px;
    height: 56px;
    margin-right: 18px;
  }

  &__text {
    width: 342px;
    height: 42px;
    font-size: 14px;
    margin: 0 0 0 20px;
  }

  &__feedback {
    & > div {
      width: 600px;
      height: 180px;
      @media screen and (max-width: 660px) {
        width: 260px;
      }
    }
    position: fixed;
    bottom: 42px;
    z-index: 9999;
  }
}