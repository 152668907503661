.course-card {
  &__wrapper {
    width: 100%;
    height: 100%;
    max-width: 344px;
    max-height: 160px;
    min-height: 120px;
    box-shadow: 0 0 9px rgba(202, 202, 210, 0.6);
    border-radius: 5px;
    background: white;
    padding: 15px 20px 34px 20px;
  }

  &__info {
    padding: 0 4px;

    &-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #4B4B60;
    }

    & > div:first-of-type {
      margin-bottom: 8px;
    }
  }

  &__addInfo {
    border-top: 1px solid #A2A2C3;
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
    margin-top: 19px;

    &-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      text-align: left;
      color: #333333;
    }
    .needToPay {
      color: #FF7A00;
    }
    .paid {
      color: #00AF01;
    }
    .processing {
      color: #333333;
    }
  }
}

.individual-courses-card {
  cursor: pointer;
}
