
  .nav-bar-item {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    color: #585c6e;
    text-decoration: none;
    border-radius: 14px;
    padding: 14px 22px;
    width: 100%;

    &-hover-area {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 200;
    }

    &:focus {
      outline: none;
    }

    &-container {
      height: 55px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;

      &_isPrompt {
        position: absolute;
      }

      &:hover {
        .nav-bar-item {
          &-text {
            color: #252232;
          }

          &_isChecked > .nav-bar-item-text {
            color: #0088e9;
          }
        }
      }
    }

    &_isHiddden,
    &_isMobile {
      padding-left: 22px;
      justify-content: center;
      text-align: center;
    }

    &_isChecked {
      color: #0088e9;
      & path,
      & rect,
      & circle {
        stroke: #0088e9 !important;
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      margin-right: 10px;

      &_isMobile {
        display: none;
      }
    }

    &-text {
      transition: width 1s ease;
      white-space: nowrap;
      width: 120px;
      &_isClosed {
        display: none;
      }
    }
  }

