.rating-table {
    &__commit {
        color: #6c757d;
        font-size: 14px;

        &-title {
            color: #131336;
            font-weight: normal;
        }
    }
}