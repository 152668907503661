.meeting-panel {
    width: 100%;
    padding: 15px !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    flex-direction: column;
    align-items: flex-start;
}

.meeting-panel__container {
    max-width: 100%;
}

@media (min-width: 768px) {
    .meeting-panel {
        padding: 25px 15px 15px !important;
    }
}

@media (min-width: 1024px) {
    .meeting-panel {
        -webkit-flex-wrap: nowrap;
            -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
    }
}

.meeting-panel__col {
    overflow: hidden;
    margin-bottom: 20px;
}

@media (min-width: 1024px) {
    .meeting-panel__col {
        margin-bottom: 0;
    }
}

.meeting-panel__col:not(:last-child) {
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
}


.meeting-panel__btn {
    width: 100%;
}

@media (min-width: 1024px) {
    .meeting-panel__btn {
        width: auto;
        margin-right: 20px;
    }
}

.meeting-panel__column-list {
    flex: unset !important;
}

.meeting-panel__events {
    margin-right: -15px;
}

@media (min-width: 1024px) {
    .meeting-panel__events {
        margin-right: 0;
    }
}

.meeting-panel__event-list {
    white-space: nowrap;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-left: -10px;
    margin-right: -10px;

}

@media (max-width: 1023px) {
    .meeting-panel__event-list {
        margin-right: 0;
        overflow-x: auto;
    }
}

@media (min-width: 770px) {
    .meeting-panel__event-list {
        max-width: 60vw;
    }
}

.meeting-panel__event-list li {
    padding: 0 10px;
    -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
}

.meeting-panel__title {
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 500;
    color: #007EEC;
}

@media (min-width: 1024px) {
    .meeting-panel__title {
    margin-right: 20px;
    max-width: 120px;
    }
}


.meeting-card {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background: #FCFCFC;
    border-radius: 5px;
    padding: 7px 25px;
    line-height: 150%;
    font-size: 0.875rem;
}

.meeting-link {
}
  
.meeting-card__header {
    color: #007EEC;
}

.meeting-card__name {
    font-weight: 500;
    color: #808A9E;
    white-space: nowrap;
}

.meeting-card__text {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid rgba(128, 138, 158, 0.12);
    max-width: 300px;
    color: #808A9E;
    white-space: normal;
}

.meeting-card__enter-link {
    text-decoration: underline;
}

.meeting-card--link {
    padding-top: 16px;
    padding-bottom: 16px;
    cursor: pointer;
    background-color: #007EEC;
    text-decoration: none !important;
    -webkit-transition: background-color 0.3s ease-in-out;
    -o-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
}

.meeting-card--link .meeting-card__header {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.meeting-card--link:hover {
    background-color: #3398f0;
}
  