.nav-bar-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  &_isClosed {
    width: unset;
  }
  &_isMobile {
    padding: 0;
    height: 70px;
    width: inherit;
  }
}
