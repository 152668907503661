.nav-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(39, 39, 39, 0.1);

  .note-wrapper {
    position: relative;
    width: 440px;
    height: 392px;
    border-radius: 20px;
    background-color: #fff;
    border: 1px solid #eef4fb;
    padding: 15px 36px 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    &_isMobile {
      width: 335px;
      padding: 15px 27px 32px;
    }
  }

  .close-button {
    position: absolute;
    right: 22px;
    top: 22px;
    cursor: pointer;
  }
}
