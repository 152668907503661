.individual-courses {
  &__wrapper {
    display: grid;
    max-width: 1260px;
    @media screen and (max-width: 1240px) {
      display: block;
    }
  }
  &__time-picker {
    margin-left: 60px;
    display: none;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    z-index: 1 !important;
    @media screen and (max-width: 1240px) {
      margin-left: 0;
      margin-top: 48px;
    }
  }
}

.individual-courses__form-container {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.individual-courses-form {
  display: flex;
  &__subject-text {
    font-size: 20px;
    font-weight: 500;
    color: rgba(46, 43, 56, 1);
    margin-bottom: 30px;
  }

  &__subject {
    display: flex;
    flex-direction: column;
    z-index: 40;

    & > .courses-header-text {
      margin-bottom: 16px;
    }

    & > div + div {
      min-width: 343px;
      @media screen and (max-width: 980px) {
        min-width: unset;
      }
    }
  }

  &__teacher-price {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 700;
    color: rgba(46, 43, 56, 1);
  }

  &__teacher {
    margin-left: 60px;
    display: none;
    z-index: 30;
    & > div + div {
      min-width: 343px;
      @media screen and (max-width: 980px) {
        min-width: unset;
      }
    }
    @media screen and (max-width: 980px) {
      margin-left: 0;
      margin-top: 48px;
    }

  }

}

.buyInAddition-individual {
  &__wrapper {
    max-width: 1260px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .course-schedule__wrapper {
      grid-row-start: 1;
      grid-row-end: 4;
      grid-column-start: 2;
      grid-column-end: 3;

      @media screen and (max-width: 980px) {
        margin-top: 30px;
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: unset;
        grid-row-end: unset;
      }
    }
  }

  &__time-picker {
    margin-left: 0;
    margin-top: 76px;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  &-buttons {
    margin-top: 80px;
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
