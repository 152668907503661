.time-picker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 64px;
    height: 28px;

    margin: 10px 0;
    margin-right: 10px;

    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #4B4B60;


    border: 1px solid #FCFCFC;
    background-color: #FCFCFC;
    border-radius: 5px;

    &:hover {
        border-color: #007EEC;
        cursor: pointer;
    }

    &__selected {
        border-color: #007EEC;
        background-color: #007EEC;
        color: #FFFFFF;
    }

    &__disabled {
        color: #E0E0E0;
        &:hover {
            border: none !important;
            cursor: default !important;
        }
    }
}