.total-rating .p-5 {
  width: 100%;

  @media screen and (max-width: 768px) {
    padding-top: 108px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    #totalRatingContent {
      & > div {
        width: 100%;
      }
    }
  }

  .tooltip {
    opacity: unset;
  }

  .pt-table-quarter-marks {
    min-width: 70px;

    .pt-table-quarter-header, .pt-table-quarter-header-label {
      min-width: 64px;
    }

    .pt-table-marks {
      min-width: 70px;
    }
  }

  p, div {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }
}

.cabinet-content {
  padding: 0;
}
