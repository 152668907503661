$cAccent: #3978EF;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);

.accordion-list{
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    max-width: 30rem;
    overflow: hidden;
  }
  
.accordion-item {
    $self: &;
  
    &--opened {    
        #{ $self }__icon {
            transform: rotate(180deg);
        }
          
        #{ $self }__inner {
            max-height: 100rem;
            transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
            transition-duration: 0.5s;
            transition-property: max-height;
        }
      
        #{ $self }__content{
            opacity: 1;
            transform: translateY(0);
            transition-delay: 0.2s;
            transition-timing-function: ease-in-out;
            transition-duration: 0.2s;
            transition-property: opacity, transform;
        }
    }
  
    &__line{
        display: block;
        // padding: 0.8rem 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        z-index: 2;
        position: relative;
    }
    
    &__title{
        font-size: 1.6rem;
        margin: 0;
        font-weight: 700;
        color: $cBlack;
    }
    
    &__icon{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        transition: transform 0.3s ease-in-out;
        color: #adb5bd;
        text-decoration: none;
        outline: none;
        -webkit-box-shadow: none;
                box-shadow: none;
        background: rgba(162, 162, 195, 0.1);
    }
    
    &__inner{
        max-height: 0;
        overflow: hidden;
        text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
        transition-duration: 0.3s;
        transition-property: max-height;
        z-index: 1;
        position: relative;
        margin-top: 15px;
    }
    
    &__content{
        opacity: 0;
        transform: translateY(-1rem);
        transition-timing-function: linear, ease;
        transition-duration: 0.1s;
        transition-property: opacity, transform;
        transition-delay: 0.5s;
        // padding: 0 1.2rem 1.2rem;
    }
    
    &__paragraph{
        margin: 0;
        font-size: 1rem;
        color: $cGray;
        font-weight: 300;
        line-height: 1.3;
    }
}