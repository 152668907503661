.documents__upload {
    min-height: 225px;
    border-radius: 5px;
    background: rgba(0, 126, 236, 0.2);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px;
    margin-bottom: 30px;
    position: relative;
    outline: none;
    border: 1px dashed #007EEC;

    &.small {
        display: flex;
        min-height: 88px;

        & .documents__upload {
            &-content {
                justify-content: flex-start;
                display: flex;
                width: 100%;
                align-items: center;
                margin-bottom: 0;
            }

            &-btn {
                margin-bottom: 0;
                margin-right: 30px;
            }
        }
    }

    &-content {
        text-align: center;
    }

    &-btn {
        margin-bottom: 20px;
    }

    &-text {
        display: block;
        color: #5D9ACF;
    }
    &-input {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }
}