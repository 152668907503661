.explanation {
    &__title {
        margin-bottom: 16px;
    }

    &__description {
        position: relative;
        margin-bottom: 52px;
        max-width: 326px;
    }

    &__arrow {
        position: absolute;
        left: 100%;
        top: calc(1em / 2);

        @media screen and (max-width: 480px) {
            display: none;
        }
    }

    &__content {
        &_with-grayscale {
            filter: grayscale(100%);
            opacity: 0.4;
        }
    }
}
