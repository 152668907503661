.dashboard__item {
    margin-bottom: 20px;
}

.card {
    -webkit-box-shadow: 0 0 9px rgba(173, 181, 189, 0.3)!important;
            box-shadow: 0 0 9px rgba(173, 181, 189, 0.3)!important;
    
    &-title {
        font-size: 16px;
        font-weight: 500;
        color: #131336!important;
    }

    &-text {
        font-size: 15px
    }

    &-footer {
        font-size: 12px!important;
        background-color: #fff !important;
        border-top: none!important;
        padding: 0;
    }
}

.grade-card {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.9375rem!important;
    color: #adb5bd;

    width: 265px;
    font-size: 12px;

    &__title {
        font-size: 14px;
    }

    &__text {
        font-size: 13px!important;
    }

    &__grade {
        padding-right: 15px;
    }
}

@media (min-width: 768px) {
    .dashboard__item {
        margin-right: 20px;
    }
}