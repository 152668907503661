.note-actions {
    &-title {
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        margin-bottom: 19px;
        margin-top: 35px;

        &_isLetter {
            text-align: center;
            font-size: 20px;
            line-height: 20px;
            width: 100%;
        }
    }

    &-buttons {
        margin-top: 16px;
        width: 336px;

        &_isLetter {
            margin: 24px auto 0;
            width: 100%;
        }
    }

    &-editButtons {
        display: flex;
    }

    &-edit-button,
    &-save-button,
    &-cancel-button {
        margin-right: 8px;
    }

    &-send-button {
        width: 132px;
    }

    &-edit {
        width: 172px;
    }

    &-cancel {
        width: 108px;
    }

    & &-delete {
        width: 40px;
        padding: 0;
        border-color: var(--color-red-main);

        .button-text {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            path {
                fill: unset;
            }
        }
    }

    & &-delete:hover {
        background-color: var(--color-red-main);
        border-color: var(--color-red-main);

        .button-text {
            path {
                stroke: var(--color-essential);
                fill: unset;
            }
        }
    }

    &-saveButtons {
        display: flex;

        &_isLetter {
            justify-content: center;
            gap: 20px;
        }
    }

    &-save {
        width: 196px;
    }

    &-send,
    &-cancelCreate {
        width: 132px;
    }

    &-text {
        &-title {
            margin-bottom: 12px;
            width: 100%;
        }

        &-input {
            height: 40px;
            width: 100%;
            box-sizing: border-box;
            border-radius: 4px;
            width: inherit;
            outline: none;
            border: 1px solid var(--color-gray-second);
            background-color: var(--color-essential);
            padding-left: 12px;
            padding-top: 0;
            font-size: 15px;
            padding-right: 28px;
            line-height: 20px;
        }
    }
}
