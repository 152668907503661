.tabsHeaderItem {
    padding: 8px 0;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    color: #C0C2C9;

    &_selected {
        color: #0088E9;
        border-bottom: 1px solid #0088E9;
    }

}
