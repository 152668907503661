.director-modal-notification {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 500 !important;
    background-color: rgba(39, 39, 39, 0.1);

    &-card {
        position: absolute;        
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 501;
    }
}
