.checkbox__wrapper {
    position: relative;
    width: 25px;
    height: 25px;
    border: 1px solid #A2A2C3;
    border-radius: 2px;
    cursor: pointer;
    
    & input {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        height: 0;
        width: 0;
    }
    & input:checked ~ .checkmark {
        position: absolute;
        
        width: 100%;
        height: 100%;

        left: 0;
        top: 0; 
        
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & input:not(:checked) ~ .checkmark {
        display: none;
    }
}

.checked {
    border-color: #00AF01 !important;
}

.checkmark {
    svg {
        path {
            stroke: #00AF01 !important;
        }
    }
}
