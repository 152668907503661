.dashboard__schedule{
    &-item {
        width: 100%;
    }
}

.dashboard{
    &__item {
        margin-bottom: 20px;
    }
}

.schedule-card {
    position: relative;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 1px solid rgba(128, 138, 158, 0.3);
    width: 100%;
    max-width: 550px;
    min-height: 130px;

    &__order {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        text-transform: lowercase;
        padding: 5px 14px;
        text-align: center;
        border-radius: 0 3px;
    }

    &__time {
        font-size: 35px;
        font-weight: 300;
        color: #007EEC;
        padding-right: 18px;
        margin-right: 20px;
        border-right: 1px solid #E1E1E7;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
    }

    &__title {
        font-size: 18px;
    }

    &__text {
        font-size: 14px;
    }

    &--current {
        border: 1px solid #007EEC!important;
    }
}
  
@media (min-width: 768px) {
    .dashboard{
        &__item {
            margin-right: 20px;
        }
    }
}