.nav-bar-desktop {
  height: 100vh;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #fff;
  transition: all 1s ease;
  border-right: 1px solid #eef4fb;
  z-index: 100;

  &-section {
    width: 100%;
    height: 100%;
  }

  &-header {
    display: flex;
    justify-content: center;
    padding: 35px 0;

    &-image {
      cursor: pointer;
      height: 24px;
      width: 24px;

      &_isOpened {
        height: 24px;
        width: 140px;
      }
    }
  }

  &_isClosed {
    .nav-bar-item {
      &-text {
        opacity: 0;
        width: 0;
      }

      &-icon {
        margin-right: 0;
      }

      &_isPrompt {
        background-color: #eef4fb;
        width: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .nav-bar-item-text {
          opacity: 1;
          width: unset;
        }
        .nav-bar-item-icon {
          margin-right: 10px;
        }
      }
    }

    .nav-bar-student {
      width: max-content;

      &-text {
        display: none;
      }

      &-settings {
        display: flex;
        align-items: center;
        padding-top: 0;
        margin-left: 8px;

        &-icon {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &-divider {
    width: 100%;
    height: 1px;
    background-color: #EDEEF2;
    transition: all 1s ease;
  }
}
