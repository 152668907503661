.settings-button {
  &__wrapper {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid rgba(162, 162, 195, 0.16);
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
