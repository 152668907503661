.cabinet-header {
    &__footer {
        width: 100%;
        background: -o-linear-gradient(210deg, #FF9166 0%, #7030FF 110%);
        background: linear-gradient(240deg, #FF9166 0%, #7030FF 110%);
        padding: 15px 40px;
        height: 54px;
        color: #fff;
        text-align: center;
        z-index: 250;

        &-link {
            color: inherit;
            text-decoration: none !important;

            &:hover, &:active, &:focus {
                color: #fff;
            }

            i {
                font-size: 22px;
            }
        }
    }
}

@media (min-width: 768px) {
    .cabinet-header{
        &__footer {
            max-width: 310px;
        }
    }
}