.documentType {
    margin-bottom: 60px;
    
    &-title {
        font-size: 25px;
        font-weight: 500;
    }

    &-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    &-description {
        font-size: 16px;
        color: #222243;

        @media screen and (max-width: 768px) {
            max-width: 100px;
        }
    }

    &-header {
        margin-bottom: 16px;
    }

    &-comment {
        display: flex;

        align-items: center;

        background: #F3F1FF;
        border-radius: 5px;

        width: 100%;

        padding: 15px 20px;

        font-weight: 500;
        font-size: 16px;

        color: #886CFF;

        margin-bottom: 18px;
    
        & div {
            font-weight: 600;
            font-size: 24px;

            color: #886CFF;

            margin-right: 20px;
        }
    }
}