.menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-list__icon {
  width: 22px;
  height: 22px;
}

@media (min-width: 768px) {
    .cabinet-header__menu-list {
      margin-left: -10px!important;
      margin-top: 38px!important;
    }
}
