.documentTypeItem {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 2px 0;

        &-header {
            flex-wrap: nowrap;
        }
    }

    &-image {
        margin: 2px 0;
        margin-right: 20px;

        img {
            width: 40px;
            height: 40px;
            border: none;
            border-radius: 4px;
        }
    }

    &-size {
        margin: 2px 0;
        font-size: 16px;
        line-height: 150%;
        margin-right: 25px;

        color: #A2A2C3;
    }

    &-name {
        margin: 2px 0;
        margin-right: 35px;

        & input {
            border: none;

            border-bottom: 1px solid #EAEAEC;
            padding: 6px;
            font-size: 16px;
        }
    }

    &-ext {
        font-size: 16px;
        font-weight: 500;
        color: #886CFF;

        border-radius: 50px;
        background: #F3F1FF;

        padding: 6px 15px 4px 15px;
        margin: 2px 0;
        margin-right: 30px;

        text-transform: uppercase;
    }

    &-buttons {
        display: flex;
        flex-direction: row;
        margin: 2px 0;
    }

    &-download {
        margin-right: 4px;
    }

    &-status {
        margin: 2px;
        margin-right: 25px;


        &.need_correction {
            background: #FFEBEA;
            border-radius: 5px;
            font-size: 16px;
            color: #FA1919;
            line-height: 150%;

            padding: 5px 15px;

            max-width: 500px;
        }

        &.recognized {
            display: flex;
            align-items: center;
            
            font-size: 16px;
            line-height: 150%;
            padding: 5px 15px;

            color: #00AF01;

            background: #E1F1DA;
            border-radius: 5px;

            & svg {
                margin-right: 10px;
            }
        }

        &.unrecognized {
            font-size: 16px;
            line-height: 150%;

            color: #A2A2C3;
        }
    }
}