
  .nav-bar-mobile-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 44px;

    &_withoutIcon {
      padding-left: 20px;
    }

    &-icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-label {
      font: 15px/20px 'Source Sans Pro';
      color: #585c6f;
    }
  }
