.settings {
  &__pageWrapper {
    padding: 0;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    @media screen and (max-width: 768px) {
      padding: 0 15px;
    }
  }

  &__wrapper {
    padding: 36px 60px 20px 60px;
    overflow-y: auto;
    height: inherit;

    @media screen and (max-width: 768px) {
      padding: 30px 0 0;
    }
  }

  &__pageHeader {
    &-container {
    }
  }

  &-photoGender {
    &__container {
      margin-top: 46px;
      display: flex;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
    &-photo {
      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__button {
        margin-left: 20px;
        width: 158px;
        height: 40px;
        border: 1px solid #0088E9;
        border-radius: 4px;
        padding: 10px 20px;
        background-color: white;
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;

        &:hover {
          background-color: #0088E9;

          span {
            color: white;
          }
        }

        span {
          font-weight: normal;
          font-size: 15px;
          line-height: 20px;
          color: #0088E9;
          white-space: nowrap;
        }
      }

      &__img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }

      &__label {
        cursor: pointer;
      }
    }
    &-gender {
      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 84px;

        & > :last-child {
          margin-left: 44px;
        }

        @media screen and (max-width: 768px) {
          margin-left: 0;
          margin-top: 30px;
        }
      }
    }
  }

  &-parentsInfo {
    &__container {
      margin-top: 48px;

      .add-button {
        width: 250px;
      }
    }

    &-inputs {
      &__container {
        div:not(:first-child) {
          margin-top: 40px;
        }
      }
    }
  }

  &-generalInfo {
    &__container {
      margin-top: 40px;
    }

    &-fio {
      &__container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 343px));
        grid-gap: 20px;
      }
    }
  }

  &-contacts {
    &__container {
      margin-top: 44px;
    }

    &-inputs {
      &-phones {
        &__container {
          display: grid;
          grid-gap: 20px;
          grid-template-columns: repeat(auto-fit, minmax(300px, 343px));

          .add-button {
            margin-top: 0;
          }

          .trash-button {
            position: absolute;
            top: 20px;
            right: 24px;
          }
        }
      }

      &-other {
        &__container {
          margin-top: 40px;
        }
      }
    }
  }

  &-documentInfo {
    &__container {
      margin-top: 40px;
      margin-bottom: 48px;
    }

    &-citizenCheck {
      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }


        & > * {
          margin-bottom: 20px;
        }

        .checkbox {
          &__wrapper {
            margin-left: 20px;
            margin-right: 10px;

            @media screen and (max-width: 768px) {
              margin-left: 0;
            }
          }
        }
      }

      &__checkBox-text {
        font-size: 16px;
        color: #808A9E;
        font-weight: normal;
      }

      &-checkBox__container {
        display: flex;
        flex-direction: row;
      }
    }

    &-inputs {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 343px));
        grid-column-gap: 20px;
        grid-row-gap: 40px;

        @media screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  &__formGroup {
    margin: 0 !important;

    label {
      visibility: visible !important;
      opacity: 1 !important;
      color: #808A9E !important;
    }

    input[type='text']:focus ~ label, input[type='date']:focus ~ label {
      color: #007EEC !important;
      transition: 0.3s;
    }

    &-error {
      & > input {
        border-color: #e33f3f;
      }
    }

    &_fullWidth {
      max-width: unset;
    }
  }

  &__block-header {
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  &__trial-block-header {
    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #252232;

    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }

  &__trial-input-subtext {
    font-weight: 400;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 0;
    color: #007EEC;

  }

  &__trial-error-message {
    font-weight: 400;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 0;
    color: #e33f3f;
    white-space: pre-wrap;
  }

  &__trial-signUpBtn {
    height: 36px;
    background-color: #0088E9;
    width: 190px;
    transition: all 0.5s linear;
  }

  &__trial-signUpBtn:disabled {
    background: #C0C2C9;
  }

  &__pupil-bar {
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }

  &__trial-info-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 60px;

    .jcf-select {
      border: 1px solid #ced4da;
      max-width: 320px;
    }

    .jcf-select-drop {
      border-radius: 5px;
      margin: 0 0 0 0;
      max-width: 320px;
    }

    .form-group--opened {
      .jcf-select {
        border-color: #007EEC;
      }
    }
  }
}

.cabinet-content {
  &__header {
    padding-left: 0;
  }
}
