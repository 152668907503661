.individual-course-time-picker{
  &__subheader {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    @media screen and (max-width: 980px) {
      font-size: 14px;
    }
  }

  &__tooltip {
    margin-bottom: 20px;
    z-index: 30;
    & > div {
      width: 360px;
      padding: 17px 18px;
      max-height: 80px;
    }
  }

  &__consultation {
    display: flex;
    align-items: normal;
    margin-top: 16px;
    margin-bottom: 20px;

    & > p {
      margin: 0 0 0 10px;
    }
    & > div:first-of-type {
      min-width: 25px;
    }
  }

  &__tooltip-trigger {
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-left: 10px;
    color: white;
    background-color: #007EEC;
    text-align: center;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  &__classes-wrapper {
    margin-top: 10px;

    & > p {
      margin-bottom: 28px;
    }
  }

  &__comments-wrapper {
    margin-top: 50px;

    & > p {
      margin-bottom: 35px;
    }
  }

  &__comments-content {
    background-color: #FCFCFC;
    border-radius: 12px;
    width: 518px;
    height: 275px;
    padding: 35px 0px 28px 28px;

    & > p {
      margin-bottom: 14px;
    }
  }
}