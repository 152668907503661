.additional-courses__default-text {
  margin-top: 13px;
  color: #4b4b60;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 343px;
  height: 44px;
}

.additional-courses {
  @media screen and (min-width: 980px) {
    display: grid;
  }
}

.additional-courses-form {
  width: 100%;
  &__schedule,
  &__schedule-error {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 4;
    @media screen and (max-width: 980px) {
      margin-left: 0;
      margin-top: 45px;
    }
  }
  &__schedule-text {
    font-size: 20px;
    font-weight: 500;
    color: rgba(46, 43, 56, 1);
    margin-bottom: 10px;
    line-height: 26px;
  }
  &__schedule-header, &__schedule-toggle {
    display: flex;
    align-items: baseline;
  }
  &__schedule-toggle {
    a:first-of-type {
      margin-right: 22px;
    }
    div {
      margin-left: 16px;
    }
  }
  &__schedule-dates {
    div:first-of-type {
      margin-top: 0px;
    }
  }
  &__subject {
    z-index: 40;
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }
}

.default-text-schedule,
.offer-text-schedule {
  width: unset;
  height: unset;
}

.offer-text-schedule {
  margin-top: 22px;
  color: rgba(0, 126, 236, 1);
}

.sub-text {
  font-size: 12px;
  color: #4b4b60;
  margin-left: 12px;
  height: 17px;
  line-height: 17px;
  @media screen and (max-width: 980px) {
    margin-left: 110px;
  }
}
.courses-buttons__error {
  color: rgba(250, 25, 25, 1);
  margin-left: 25px;
}
.text-error {
  color: rgba(250, 25, 25, 1);
  margin: 0;
}

.sub-text-error {
  margin: 4px 0 5px 0;
}

.additional-courses-buttons {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  @media screen and (max-width: 980px) {
    margin-top: 60px;
  }
}
