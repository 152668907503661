.checkbox-ios {
  display: inline-block;
  position: relative;
  margin: 0;
}

.checkbox-ios .checkbox-ios-switch {
  display: inline-block;
  box-sizing: border-box;
  width: 32px;
  height: 20px;
  border-radius: 30%/50%;
  vertical-align: top;
  background: #BFC1C7;
  transition: .2s;
  cursor: pointer;

  .icon {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    top: -6%;
    left: 18%;
    border: none;

    svg {
      width: 8px;
      height: 8px;
    }

    svg>path {
      fill: #BFC0C7;
    }
  }

  .icon-right {
    display: none;
  }
}

.checkbox-ios .checkbox-ios-switch::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #fff;
  transition: .4s;

}

.checkbox-ios input[type=checkbox] {
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-ios input[type=checkbox]:checked+.checkbox-ios-switch {
  background: #0088E9;

  .icon {
    display: none;
  }

  .icon-right {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    top: -6%;
    left: 57%;

    svg {
      width: 8px;
      height: 8px;
    }
  }
}

.checkbox-ios input[type=checkbox]:checked+.checkbox-ios-switch:before {
  transform: translateX(12px);
}

.checkbox-ios input[type=checkbox]:disabled+.checkbox-ios-switch {
  background: #0088E9;
  opacity: 0.48;
  cursor: default;
}

.checkbox-ios input[type=checkbox]:disabled:not(:checked)+.checkbox-ios-switch {
  background: #BFC1C7;
  opacity: 0.48;
  cursor: default;
}

.checkbox-ios input[type=checkbox]:disabled+.checkbox-ios-switch:before {
  background: #eee;
}
