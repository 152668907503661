.schedule__table__wrapper {
    overflow-y: auto;
}

.schedule__table-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 0;
}