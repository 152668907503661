.DesktopH2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  font-style: normal;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
}
.SubTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  font-style: normal;
}
.DesktopH3 {
  font-weight: 600;
  font-size: 17px;
  line-height: 21.25px;
  font-style: normal;
  @media (max-width: 768px) {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
  }
}
.DesktopH4 {
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  font-style: normal;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 16.34px;
  }
}
.DesktopFontBody {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  font-style: normal;
}
.DesktopFootNotes {
  font-weight: 400;
  font-size: 13px;
  line-height: 15.6px;
  font-style: normal;
}
.Body {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  font-style: normal;
}
.Title {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  font-style: normal;
}
.Little {
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  font-style: normal;
}
