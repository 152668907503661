.course-time-picker {
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        color: #4B4B60;
    }
    &__header {
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
        color: #2E2B38 !important;
        margin-bottom: 12px;
    }
    &__title {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        margin: 0;
    }
    &__date-time{
        display: flex;
        flex-direction: row;
        align-items: baseline;

        margin-bottom: 10px;
    }
    &__date{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        width: 148px;

        @media screen and (max-width: 728px) {
            width: 136px;
        }

        margin: 0;
        padding-top: 2px;
    }
    &__time{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        max-width: 296px;//max-width can changed
        margin-left: 28px;

        @media screen and (max-width: 728px) {
            max-width: 222px;
        }
    }
}

.test-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    overflow-y: auto;
}