.schedule__date {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    font-size: 25px;
    width: 100%;
    margin: 25px auto 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;

    .icon-circle-arrow {
        -webkit-box-shadow: none;
                box-shadow: none;
        background: rgba(162, 162, 195, 0.1);

        &:first-child {
            margin-right: 20px;
        }
    }

    &-month {
        font-size: 20px;
        font-weight: 500;
        color: #adb5bd;
        margin-right: 20px;
    }

    &-days {
        margin-right: 10px;
    }

    &-arrows a {
        width: 34px;
        height: 34px;
        -webkit-box-shadow: none;
                box-shadow: none;
        background: rgba(162, 162, 195, 0.1);
        margin: 0 5px;
    }
}

@media (min-width: 768px) {
    .schedule__date {
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        width: auto;
        margin: 0 0 0 40px;

        .icon-circle-arrow {
            -webkit-box-ordinal-group: 4;
            -webkit-order: 3;
            -ms-flex-order: 3;
                order: 3;

            &:first-child {
                margin-right: 10px;
            }
        }
    }
}