.textarea-field-core {
    display: flex;
    position: relative;
    width: 100%;

    &-input {
        max-height: 200px;
        min-height: 80px;
        border-radius: 4px;
        width: inherit;
        outline: none;
        border: 1px solid #00103d1f;
        background-color: #fff;
        padding-left: 12px;
        padding-top: 10px;
        font-size: 15px;
        color: #252232;
        resize: vertical;
        padding-right: 28px;

        &_isLetter {
            min-height: 150px;
        }

        &:focus {
            outline: none;
            border: 1px solid #00103d7a;
            background-color: #fff;
        }

        &_disabled {
            pointer-events: none;
            cursor: default;
            opacity: 0.5;
            background-color: #edeef2;
            color: #919399;
            user-select: none;
            padding-right: 12px;
        }

        &_notValid {
            outline: none;
            border: 1px solid #ea5959;
        }
    }

    &-icon {
        display: none;

        &_notEmpty {
            display: initial;
            position: absolute;
            top: 16px;
            transform: translateY(-50%);
            right: 16px;
            height: min-content;
            transition: all 0.2s;
            cursor: pointer;

            svg {
                width: 8px;
                height: 8px;
            }
        }
    }
}
