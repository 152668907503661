.courses {
  padding-bottom: 90px;
}

.courses-header {
  margin-top: 40px;
  margin-bottom: 40px;
  height: 38px;
  display: flex;
  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin-bottom: 22px;
    height: unset;
    width: 100%;
  }
}

.courses-header-text {
  font-size: 25px;
  font-weight: 500;
  color: rgba(19, 19, 54, 1);
  @media screen and (max-width: 980px) {
    margin-bottom: 8px;
  }
}

.courses-header-radio {
  width: 220px;
  word-wrap: break-word;
  font-size: 15px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 980px) {
    margin-left: 0;
    width: 260px;
  }
  @media screen and (max-width: 530px) {
    height: 56px;
  }
}

.individual-courses-form__subject-text,
.additional-courses-form__subject-text {
  font-size: 20px;
  font-weight: 500;
  color: rgba(46, 43, 56, 1);
  margin-bottom: 30px;
  @media screen and (max-width: 980px) {
    margin-bottom: 12px !important;
  }
}

.individual-courses-form__teacher-price {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 700;
  color: rgba(46, 43, 56, 1);
}

.individual-courses-form,
.additional-courses-form {
  display: flex;
  @media screen and (max-width: 980px) {
    flex-direction: column;
  }
}

.signup-btn {
  color: rgba(255, 255, 255, 1);
  min-width: 220px;
  height: 54px;
  background-color: rgba(0, 126, 236, 1);
  border-color: rgba(0, 126, 236, 1);
  @media screen and (max-width: 980px) {
    max-width: 220px;
    height: 78px;
  }
}

.signup-btn:disabled {
  background-color: rgba(128, 138, 158, 1);
  border-color: rgba(128, 138, 158, 1);
  visibility: visible;
}

.cancel-btn {
  background-color: rgba(255, 235, 234, 1);
  border-color: rgba(255, 235, 234, 1);
  color: rgba(250, 25, 25, 1);
  width: 124px;
  height: 54px;
  margin-left: 18px;
  @media screen and (max-width: 980px) {
    width: 136px;
    height: 78px;
  }
}

.individual-courses-buttons,
.additional-courses-buttons {
  flex-direction: column;
  display: flex;
  margin-top: 145px;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  z-index: 2;
  @media screen and (max-width: 980px) {
    margin-top: 70px;
  }
}

.courses-buttons {
  &__signup {
    max-width: 516px;
    display: flex;
    align-items: center;
  }

  &__prepay {
    margin-bottom: 28px;
  }

  &__prepay-text {
    margin-left: 8px;
  }
}
