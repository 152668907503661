
  .nav-bar-desktop-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;

    &_isOpened {
      flex-direction: row;
      margin-left: 16px;
      margin-right: 18px;
      justify-content: space-between;
    }

    &-flex-box {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      &_isOpened {
        flex-direction: row-reverse;
        height: 40px;
        margin-bottom: 0;
      }
      @media screen and (max-width: 1200px) {
        flex-direction: column;
      }
    }

    &-button {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      border: 1px solid;

      &_isOpened {
        width: 60px;
      }
      &_isSmart {
        border-color: #0088e9;
        margin-bottom: 8px;
      }
      &_isDownload {
        border-color: #2f929a;
      }

      &-icon {
        width: 32px;
        height: 32px;
        cursor: pointer;
      }
    }

    &-principal {
      margin-bottom: 8px;
      height: 40px;

      &_isOpened {
        margin-bottom: 0;
      }

      &-button {
        width: 180px;
        display: none;
        border-radius: 20px;

        &_isOpened {
          display: block;
        }

        &_isMobile {
          display: block;
          width: 100%;
          padding: 0 20px 30px 20px;
          box-sizing: border-box;
        }
      }

      &-icon {
        cursor: pointer;

        &_isOpened {
          display: none;
        }
      }
    }

    &-close {
      &-button {
        height: 40px;

        &_onlyToggle {
          margin-left: 20px;
        }

        &_hasPrincipal {
          margin-left: 10px;
        }

        &_isClosed {
          margin-left: 0;
        }
      }

      &-icon {
        display: flex;
        width: 40px;
        height: 40px;
        fill: none;
        cursor: pointer;

        &_isOpened {
          transform: rotate(180deg);
        }

        &_checked path,
        &_checked rect {
          stroke: #0088e9 !important;
        }

        & path,
        rect {
          stroke: #585c6e;
        }

        &_isDisabled {
          rect {
            stroke: #c0c2c9;
          }

          path {
            stroke: #c0c2c9;
          }

          #right {
            stroke: #c0c2c9;
          }
        }
      }
    }
  }
