.loading-schedule {
    & .schedule-card--pl {
        width: 100%;
        max-width: 370px;
        min-height: auto;
    }

    & .schedule-card--pl .schedule-card__title {
        margin-bottom: 15px !important;
    }

    & .schedule-card--pl .schedule-card__time {
        margin-right: 30px;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }

    & .schedule-card--pl .schedule-card__text {
        width: 100%;
        max-width: 195px;
        margin-bottom: 10px;
    }

    & .schedule-card--pl .schedule-card__footer {
        width: 100%;
        max-width: 110px;
        background-color: #f4f4f4 !important;
    }
}
