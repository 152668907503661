
.nav-bar-mobile {
    display: flex;
    height: 70px;
    background-color: #FFF;
    position: fixed;
    width: 100%;

    &-container {
      display: grid;
      grid-template-rows: 70px max-content;
      width: inherit;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 22px;
      gap: 13px;
      background-color: #FFF;
      z-index: 20;
      position: relative;
      border-bottom: 1px solid #eaeaec
    }

    &-student {
      margin-left: auto;
    }

    &-burger {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      cursor: pointer;

      &-item {
        width: 22px;
        height: 2px;
        background: #0088e9;
        border-radius: 8px;
        margin: 3px;

        &_isLeft {
          position: absolute;
          transform: rotate(45deg);
        }

        &_isRight {
          transform: rotate(-45deg);
        }
      }
    }

    &-mobile-content {
      position: fixed;
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: #FFF;
      margin-right: 10px;
      padding-top: 70px;
      box-sizing: border-box;
    }

    &-content {
      position: fixed;
      overflow-y: scroll;
      width: 100%;
      height: 100%;
      z-index: 10;
      background-color: #FFF;
      margin-right: 10px;
      padding-top: 70px;
      box-sizing: border-box;
    }
    &-icons-container {
      display: flex;
      justify-content: space-around;
      margin: 35px 0;
    }

    &-icon {
      background: #FFF;
      border: 1px solid #eef4fb;
      border-radius: 50%;
      height: 48px;
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      :hover {
        background-color: #eef4fb;
      }

      &-group {
        display: flex;
        justify-content: space-around;
        margin: 35px 0;
      }

      .tooltip-item {
        .icon-wrapper {
          margin: 0;
          width: 100%;
        }
      }
    }

    &-principal-button {
      display: block;
      width: 100%;
      padding: 0 20px 30px 20px;
      border-radius: 20px;
      box-sizing: border-box;
      margin: 0;
    }
  }
