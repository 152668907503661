.dashboard__range {
    .d-flex {
        max-width: 550px;
        box-sizing: content-box;
        gap: 20px;
    }

    .dashboard__item {
        margin: 0;
    }
}
