.performance__table{
    &-empty {
        display: flex;
        justify-content: center;



        & span {
            text-align: center;
            color: #c1c1c1;
            font-size: 13px;
            display: block;
            margin-top: 10px;
        }
    }
    
    &-cell {
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative;

        &::after {
            position: absolute;
            content: '';
            top: 100%;
            right: 0;
            width: 1px;
            height: 100%;
            background-color: #f7f7f7;
        }

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            border-right: 1px solid #f7f7f7;
        }
        &.active {
            & .subject:hover {
                color: #007EEC !important;
                text-decoration: none;
            }
            & .subject:active {
                color: unset;
            }
        }
    
        &.disabled {
            & .subject {
                background-color: #f2f2f2!important;
            }
            & a {
                text-decoration: none;
                color: #495057;
                cursor: auto;
            }
        }
    }
}

.subject {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    font-size: 18px;
    background-color: #e9ecef;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    min-height: 90px;
    font-weight: 500;
    color: #495057;
    text-decoration: none;
    -webkit-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;

    &:active {
        -webkit-transition-delay: 0s;
             -o-transition-delay: 0s;
                transition-delay: 0s;
        color: #007EEC !important;
    }

    &__icon {
        position: absolute;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        top: 10px;
        right: 10px;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: white;
        font-size: 12px;
    }
}

@media (min-width: 768px) {
    .performance__table-cell {
      border-bottom: 1px solid #f7f7f7;
      border-left: 0;
      padding-top: 35px;
      padding-bottom: 35px;
    }

    .subject {
        border: 1px solid #adb5bd;
        background-color: #fff;
        max-width: 225px;
        min-height: 60px;

        &__icon {
            top: auto;
            bottom: 100%;
            left: 100%;
            -webkit-transform: translate(-50%, 50%);
                -ms-transform: translate(-50%, 50%);
                    transform: translate(-50%, 50%);
        }
    }
  }